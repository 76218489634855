import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Separator } from '../..';

const MainPart = (props) => {
  const { title, children } = props;
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-2">
        <Typography
          className="text-left"
          variant="h2"
          size="mdxl"
          weight="bold"
        >
          {title}
        </Typography>
        <Separator />
      </div>
      <div className="flex flex-col space-y-4">
        {children}
      </div>
    </div>
  );
};

MainPart.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default MainPart;
