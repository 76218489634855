import React from 'react';
import PropTypes from 'prop-types';

const List = (props) => {
  const { children } = props;

  return (
    <ul className="flex flex-col">
      {children}
    </ul>
  );
};

const Item = (props) => {
  const { children } = props;

  return (
    <li className="relative pl-5 py-2 before:top-[19px] before:absolute before:rounded-[50%] before:left-0 before:w-[8px] before:h-[8px] before:bg-primary">
      {children}
    </li>
  );
};
List.Item = Item;

List.propTypes = {
  children: PropTypes.node,
};
List.defaultProps = {
  children: null,
};

Item.propTypes = {
  children: PropTypes.node,
};
Item.defaultProps = {
  children: null,
};

export default List;
