import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Image, Typography, ImgViewer } from '../..';

const Figure = (props) => {
  const {
    imageName, caption, imgClassName, maxWidth, currentOpTitle,
  } = props;

  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  return (
    <>
      <ImgViewer
        isOpen={viewerIsOpen}
        imageName={imageName}
        close={() => setViewerIsOpen(false)}
        alt={`${currentOpTitle} - ${caption}`}
      />
      <div
        className={`cursor-pointer flex flex-col ${maxWidth} shadow hover:shadow-lg rounded border-2 border-[#C4E1FF] p-[3px] transition duration-150`}
        onClick={() => setViewerIsOpen(true)}
        aria-hidden="true"
      >
        <Image className={`${imgClassName} ${caption ? 'rounded-t ' : 'rounded'}`} imageName={imageName} alt={`${currentOpTitle} - ${caption}`} />
        {caption ? (
          <Typography className="bg-[#eff7ff] px-2 py-3 text-center" size="sm" color="caption">
            {caption}
          </Typography>
        ) : null}
      </div>
    </>
  );
};

Figure.propTypes = {
  imageName: PropTypes.string.isRequired,
  caption: PropTypes.string,
  imgClassName: PropTypes.string,
  maxWidth: PropTypes.string,
  currentOpTitle: PropTypes.string.isRequired,
};

Figure.defaultProps = {
  maxWidth: 'max-w-[300px]',
  caption: '',
  imgClassName: '',
};

export default Figure;
