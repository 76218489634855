import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Image, Typography, ImgViewer } from '../..';

const FigureTriple = (props) => {
  const {
    imageNameOne, imageNameTwo, imageNameThree, caption, maxWidth, height, currentOpTitle,
  } = props;

  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [selectedImageName, setSelectedImageName] = useState(null);
  const [selectedCaption, setSelectedCaption] = useState(null);

  return (
    <>
      <ImgViewer
        isOpen={viewerIsOpen}
        imageName={selectedImageName}
        close={() => setViewerIsOpen(false)}
        alt={`${currentOpTitle} - ${selectedCaption}`}
      />
      <div className={`cursor-pointer flex flex-col ${maxWidth} shadow hover:shadow-lg rounded border-2 border-[#C4E1FF] p-[3px] transition duration-150`}>
        <div className={`flex space-x-[3px] h-full ${height}`}>
          <div onClick={() => { setViewerIsOpen(true); setSelectedImageName(imageNameOne); setSelectedCaption(`Image 1 ${currentOpTitle} - ${caption}`); }} aria-hidden="true"><Image className={`${caption ? 'rounded-t' : 'rounded'} w-full h-full`} imageName={imageNameOne} alt={`Image 1 ${currentOpTitle} - ${caption}`} /></div>
          <div onClick={() => { setViewerIsOpen(true); setSelectedImageName(imageNameTwo); setSelectedCaption(`Image 2 ${currentOpTitle} - ${caption}`); }} aria-hidden="true"><Image className={`${caption ? 'rounded-t' : 'rounded'} w-full h-full`} imageName={imageNameTwo} alt={`Image 2 ${currentOpTitle} - ${caption}`} /></div>
          <div onClick={() => { setViewerIsOpen(true); setSelectedImageName(imageNameThree); setSelectedCaption(`Image 3 ${currentOpTitle} - ${caption}`); }} aria-hidden="true"><Image className={`${caption ? 'rounded-t' : 'rounded'} w-full h-full`} imageName={imageNameThree} alt={`Image 3 ${currentOpTitle} - ${caption}`} /></div>
        </div>
        {caption ? (
          <Typography className="bg-[#eff7ff] px-4 py-3 text-center" size="sm" color="caption">
            {caption}
          </Typography>
        ) : null}
      </div>
    </>
  );
};

FigureTriple.propTypes = {
  imageNameOne: PropTypes.string.isRequired,
  imageNameTwo: PropTypes.string.isRequired,
  imageNameThree: PropTypes.string.isRequired,
  caption: PropTypes.string,
  maxWidth: PropTypes.string,
  height: PropTypes.string,
  currentOpTitle: PropTypes.string.isRequired,
};

FigureTriple.defaultProps = {
  maxWidth: 'max-w-[800px] ',
  height: 'h-[250px]',
  caption: '',
};

export default FigureTriple;
