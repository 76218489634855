import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '../..';

const MainTitle = (props) => {
  const { currentOpTitle } = props;

  return (
    <Typography
      className="border-[4px] py-4 px-8 border border-[#a0d1ff] uppercase text-center tracking-[0.3em]"
      variant="h1"
      color="primary"
      size="mdxl"
      weight="bold"
    >
      {currentOpTitle}
    </Typography>
  );
};

MainTitle.propTypes = {
  currentOpTitle: PropTypes.string.isRequired,
};

export default MainTitle;
