import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, RandomOp, Typography } from '../../components';
import {
  MainTitle, MainPart, Figure, List,
} from '../../components/opComponents';

const ArthroseAcromioClaviculaire = () => (
  <Layout
    title="Arthrose acromio-claviculaire : Diagnostic et traitement"
    description="L'Arthrose acromio-claviculaire est une destruction du cartilage articulaire situé entre la clavicule et l'acromion, découvrez les traitements possibles."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-de-lepaule" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations de l&apos;épaule</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Arthrose acromio-claviculaire" />
        <MainPart title="Qu'est-ce que l'arthrose acromio-claviculaire ?">
          <Typography>
            <Typography variant="span" weight="bold">
              L&apos;arthrose acromio-claviculaire est une destruction du cartilage articulaire
            </Typography>
            {' '}
            situé entre la clavicule et l&apos;acromion associée à l&apos;apparition de becs osseux
            (ostéophytes) responsables d&apos;une gêne fonctionnelle de l&apos;épaule.
          </Typography>
        </MainPart>
        <MainPart title="Comment s'articule la clavicule ?">
          <Typography>
            La clavicule s&apos;articule avec le sternum (manubrium)
            médialement et avec la scapula (acromion)
            latéralement. Elle est stabilisée par les ligaments coraco-claviculaires
            (entre la coracoïde et la clavicule) et les ligaments acromio-claviculaires
            (entre l&apos;acromion et la clavicule) (Figure 1).
          </Typography>
          <Figure imageName="figure1-arthroseacromioclaviculaire.jpg" caption="Figure 1. Anatomie osseuse et ligamentaire de l&apos;épaule" currentOpTitle="Arthrose acromio claviculaire" />
        </MainPart>
        <MainPart title="Quelles sont les causes possibles de l'arthrose acromio-claviculaire ?">
          <Typography>
            L&apos;arthrose acromio-claviculaire peut survenir dans
            les suites d&apos;un traumatisme ou dans le
            cadre de microtraumatismes (liés à des gestes répétitifs) de l&apos;épaule.
            Cette pathologie est fréquemment rencontrée
            {' '}
            <Typography variant="span" weight="bold">
              chez les haltérophiles, les bodybuilders
            </Typography>
            {' '}
            ou les patients dont le travail nécessite le port de
            charges lourdes au-dessus de la tête.
          </Typography>
        </MainPart>
        <MainPart title="Quels sont les symptômes de l'arthrose acromio-claviculaire ?">
          <div>
            <Typography weight="bold">
              Une tuméfaction est parfois visible au niveau de
              l&apos;articulation acromio-claviculaire
              {' '}
              <Typography variant="span">
                en rapport avec une saillie des becs osseux (ostéophytes).
              </Typography>
            </Typography>
            <Typography>
              L&apos;articulation acromio-claviculaire est stable
              (absence de tiroir antéro-postérieur et de touche de piano).
            </Typography>
            <Typography>
              La douleur, souvent absente au repos, est réveillée
              par la palpation et la mobilisation de l&apos;articulation
              acromio-claviculaire (Figure 2).
            </Typography>
          </div>
          <Figure imageName="figure2-arthroseacromioclaviculaire.jpg" caption="Figure 2. Repères osseux et articulation acromio-claviculaire" currentOpTitle="Arthrose acromio claviculaire" />
          <Typography>
            La manœuvre de mise en compression de l&apos;articulation acromio-claviculaire
            (
            <a className="underline text-link hover:text-primary" href="https://www.physio-pedia.com/Scarf_Test" target="_blank" rel="noreferrer">Cross-Body Arm Test</a>
            ) reproduit souvent les douleurs (Figure 3).
          </Typography>
          <Typography>
            Le test de conflit sous-acromial est négatif.
            L&apos;examen de la coiffe des rotateurs est normal.
          </Typography>
          <Figure imageName="figure3-arthroseacromioclaviculaire.jpg" caption="Figure 3. “Cross-Body Arm Test” déclenchant des douleurs au niveau de l&apos;articulation acromio-claviculaire" currentOpTitle="Arthrose acromio claviculaire" />
        </MainPart>
        <MainPart title="Quels sont les examens qui permettent de confirmer de l'arthrose acromio-claviculaire ?">
          <Typography>
            Les
            {' '}
            <Typography variant="span" weight="bold">
              radiographies de l&apos;épaule
            </Typography>
            {' '}
            avec un cliché centré sur l&apos;articulation acromio-claviculaire permettent de
            faire le diagnostic d&apos;arthrose (pincement articulaire, ostéophytes,
            géodes et sclérose sous-chondrale) (Figure 4).
          </Typography>
          <Figure maxWidth="max-w-[400px]" imageName="figure4-arthroseacromioclaviculaire.jpg" caption="Figure 4. Radiographie de face: arthrose acrromio-claviculaire" currentOpTitle="Arthrose acromio claviculaire" />
          <Typography>
            Le plus souvent un Arthro-scanner (scanner avec injection de produit de
            contraste dans l&apos;articulation) ou une IRM est également prescrit afin
            d&apos;éliminer une pathologie de la coiffe des rotateurs et/ou du long
            biceps associée.
          </Typography>
        </MainPart>
        <MainPart title="Quels sont les traitements médicaux et chirurgicaux en cas d'arthrose acromio-claviculaire ?">
          <div>
            <Typography>
              Le traitement non chirurgical est indiqué dans un premier temps.
              Le repos avec l&apos;arrêt,
              au moins temporaire, de la musculation et du port de poids au-dessus
              du plan de l&apos;épaule
              est primordial. La prise d&apos;antalgiques, d&apos;anti-inflammatoires
              non-stéroïdiens (7 jours maximum)
              permettent également de soulager la douleur. La rééducation
              et la physiothérapie antalgique est également conseillée.
              En cas de persistance des douleurs, une infiltration écho
              ou radio-guidée de corticoïdes
              au niveau de l&apos;articulation acromio-claviculaire doit être prescrite.
            </Typography>
            <Typography>
              Cette infiltration, qui sert également de test diagnostique,
              est indispensable avant d&apos;envisager un traitement chirurgical.
            </Typography>
          </div>
          <div>
            <Typography>
              Le traitement chirurgical est indiqué lorsque les douleurs persistent malgré un
              traitement médical bien conduit et lorsque l&apos;origine des douleurs
              a été prouvée par l&apos;efficacité transitoire d&apos;une infiltration
              acromio-claviculaire écho ou radio-guidée.
            </Typography>
            <Typography>
              La chirurgie consiste à réaliser une résection d‘environ 1 centimètre de
              l&apos;articulation acromio-claviculaire sous arthroscopie afin
              de ne pas déstabiliser la clavicule (Figure 5).
            </Typography>
          </div>
          <Figure imageName="figure5-arthroseacromioclaviculaire.jpg" caption="Figure 5. Vue arthroscopique pré-opératoire après résection acromio-claviculaire" currentOpTitle="Arthrose acromio claviculaire" />
          <Typography>
            Cette opération est le plus souvent réalisée en ambulatoire, sous anesthésie
            générale. Une anesthésie locorégionale (bloc inter-scalénique) peut y
            être associé afin de limiter les douleurs post-opératoires.
            Dans les suites de l&apos;opération, l&apos;épaule est immobilisée
            de manière non stricte
            à l&apos;aide d&apos;un gilet orthopédique pendant 2 semaines (Figure 6).
          </Typography>
          <Figure imageName="figure6-arthroseacromioclaviculaire.jpg" caption="Figure 6. Gilet orthopédique coude au corps" currentOpTitle="Arthrose acromio claviculaire" />
        </MainPart>
        <MainPart title="Quelles sont les possibles complications après une opération chirurgicale pour de l'arthrose acromio- claviculaire ? ">
          <Typography>
            En cas de réalisation de l&apos;opération par un chirurgien spécialisé du
            membre supérieur, les risques de complication sont rares. Il faut
            néanmoins prendre en compte les complications suivantes :
          </Typography>
          <List>
            <List.Item>
              <Typography weight="bold">
                Complications liéees à l&apos;anasthésie
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Lésions iatrogènes
                {' '}
                <Typography variant="span">
                  plexus brachial, des vaisseaux sous-claviers et du poumon.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Infection :
                {' '}
                <Typography variant="span">
                  rare en raison de l&apos;application des règles d&apos;hygiène et de
                  l&apos;administration d&apos;une antibioprophylaxie pendant
                  l&apos;opération. Le germe le plus souvent incriminé est le P.Acnès.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Hématome :
                {' '}
                <Typography variant="span">
                  très rare en arthroscopie d&apos;épaule
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Douleurs résiduelles :
                {' '}
                <Typography variant="span">
                  les douleurs acromio-claviculaires peuvent persister
                  pendant plusieurs mois. Cependant on observe une nette
                  atténuation des douleurs dès le 3 mois post-opératoire.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Raideur de l&apos;épaule :
                {' '}
                <Typography variant="span">
                  liée à l&apos;immobilisation post-opératoire.
                  L&apos;évolution est le plus souvent favorable avec les séances de rééducation.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Capsulite rétractile (ou algodystrophie) :
                {' '}
                <Typography variant="span">
                  se traduisant par des douleurs et un enraidissement de l&apos;épaule
                  pouvant atteindre tout le membre supérieur. Il s&apos;agit d&apos;une
                  complication non prévisible, pouvant durer 12 à 18 mois.
                </Typography>
              </Typography>
            </List.Item>
          </List>
        </MainPart>
      </div>
    </div>
    <RandomOp type="epaule" />
  </Layout>
);

export default ArthroseAcromioClaviculaire;
